import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class FooterMain extends Component {
    state = {}
    handleScrollToStats = () => {
        window.scrollTo({
            top: 0
        })
   }

    render() {
        return (
            <footer className="footer-wrap p-t-40 p-b-20">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-3">
                            <div className="footer-logo">
                                <Link to="/home"><img src="assets/images/MyRelo-logo-white.png" /></Link>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-3">
                            <h6 className="text-white m-t-0 text-uppercase">Company</h6>
                            <ul className="p-0">
                                <li><Link to="/terms" onClick={this.handleScrollToStats}>Terms of Service</Link></li>
                                <li><Link to="/privacy-policy" onClick={this.handleScrollToStats}>Privacy Policy</Link></li>
                                <li><Link to="/subscription-agreement" onClick={this.handleScrollToStats}>Subscription Agreement</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <h6 className="text-white m-t-0 text-uppercase">Resources</h6>
                            <ul className="p-0">
                                <li><Link to="/contact-us" onClick={this.handleScrollToStats}>Contact us</Link></li>
                                <li><Link  to="/about" onClick={this.handleScrollToStats}>About</Link></li>
                            </ul>
                        </div>

                        <div className="col-md-3 col-sm-3">
                            <h6 className="text-white m-t-0 text-uppercase">Resources</h6>
                            <ul className="p-0 app">
                                <li><a href="#"><img src="assets/images/Apple_AppStore.png" alt="" /></a></li>
                                <li><a href="#"><img src="assets/images/Google_PlayStore.png" alt="" /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center p-t-20">
                            <p>&copy; 2019 MyRelo. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterMain;
