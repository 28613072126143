import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import Urls from '../externaljs/Urls';

class HeaderMain extends Component {
    toggleNavbar = (authType) => {
        window.$('body').toggleClass('show-menu');
        this.setState({ authType })
    }
    state = {
        authType: null
    }

    componentDidMount() {
        window.$('.myrelo-header .navbar-toggle').click(function (e) {
            e.preventDefault();
            window.$('body').toggleClass('show-menu');
        });
        window.$('.owl-carousel').owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            dots: true,
            navText: [
                "<i class='fa fa-caret-left'></i>",
                "<i class='fa fa-caret-right'></i>"
            ],
            autoplay: true,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 4
                },
                1000: {
                    items: 4
                }
            }
        });
    }

    navToAuth = (roleName) => {
        const url = Urls[`${roleName}${this.state.authType}`]  //i.e.  customerLogin / customerSignup
        if (url)
            window.location.href = url;
    }


    render() {
        const { navToBuild } = this.props;
        return (
            <Fragment>
                <header className="myrelo-header">
                    <div className="container">
                        <a className="logo" to="/home"><img src="assets/images/MyRelo-logo-black.png" alt="Logo" /></a>
                        <ul className="navbar">
                            <li><Link to="/home">Home</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/cusfor">For Customer</Link></li>
                            <li><a href="javascript:void(0)" onClick={() => navToBuild(Urls.mover)}>For Mover</a></li>
                            <li><Link to="" data-toggle="modal" data-target="#myReloAuthModal" className="header-login-btn" onClick={() => this.toggleNavbar('Login')}>Login</Link></li>
                            <li><Link to="" data-toggle="modal" data-target="#myReloAuthModal" className="btn btn-danger btn-rounded p-l-15 p-r-15 f-s-14" onClick={() => this.toggleNavbar('Signup')}>Sign UP For Free</Link></li>
                        </ul>
                        <span className="navbar-toggle"></span>
                    </div>
                </header>

                <div id="myReloAuthModal" className="modal fade" role="dialog">
                    <div className="modal-dialog">


                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Please Choose Your Role </h4>
                            </div>
                            <div className="modal-body p-t-40 p-b-40">
                                <div className="icon-grid-wrap">
                                    <div className="icon-grid">
                                        <a href="javascript:void(0)" data-dismiss="modal" onClick={() => this.navToAuth('customer')}>
                                            <img src="assets/images/icons/black/customerIcon.svg" width="70" alt="" />
                                            <h4 className="p-t-10 m-0 f-s-18">Customer</h4>
                                        </a>
                                    </div>
                                    <div className="icon-grid">
                                        <a href="javascript:void(0)" data-dismiss="modal" onClick={() => this.navToAuth('mover')}>
                                            <img src="assets/images/icons/black/moving-truck.svg" width="70" alt="" />
                                            <h4 className="p-t-10 m-0 f-s-18">Mover</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default HeaderMain;