import React, { Component } from 'react';
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';

class TermsofService extends Component {
    state = {}
    render() {
        return (
            <div>
                <HeaderMain />
                <section className="inner-banner section-gap" style={{background: 'url(assets/images/privacy-banner.jpg) no-repeat', minHeight: '300px'}}>
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-left text-white">
                <div className="banner-title">
                    <h1 className="m-0 p-t-20 text-dark-blue"><span className="text-red">MyRelo.us</span> Terms of Use</h1>
                </div>
            </div>
        </div>
    </div> 
</section>

<section className="page-body-wrap p-t-40 p-b-40">
    <div className="container">
        <div className="row">
            <div className="page-section-1">
                <div className="col-md-12">
                    <div className="page-content">
                        <h3>General terms </h3>
                        <p className="m-0 text-sm-center text-xs-center">
                            The MyRelo.us website and mobile App (the "Platforms") is own by, Courageous Endeavors Inc. and registered in Sycamore, Georgia, with a registered mailing address at PO Box 210 Sycamore, Georgia 31790-0210 ("MyRelo.us", "us", "we" or "our").
                        </p><br/>
                         <p className="m-0 text-sm-center text-xs-center">
                            As a user of our website and App, You acknowledge and consent to the terms of use ("service Terms"). Perhaps you do not agree to these Terms, you must abruptly desist from the use of this website, and also uninstalling our App from you device. This Terms of service should be read in alongside with our Privacy Policy and Cookie Policy.
                        </p>
                        <hr/>
                        <h3>Background</h3>
                        <p className="m-0 text-sm-center text-xs-center">MyRelo.us platform is an integral part of Courageous Endeavors, Inc., setup to redefine the relocation industry communication network architecture. MyRelo.us creates a grid to which all other parties can interconnect. MyRelo.us sees this area of opportunity to invest and take advantage by helping to increase customer base across the industry.</p><br/>
                        <h5 className="m-0 text-sm-center text-xs-center">
                           Our website and App can be used on an individual and a commercial basis:
                        </h5></div><br/>
                        <ul className="">
                        <li><b>1.  Consumer basis</b></li>
                        <p className="p-l-15">Users have opportunity to shop for items, and purchase desired services through our affiliate links. </p>
                        <li><b>2. Commercial basis</b></li>
                        <p className="p-l-15">i.  MyRelo.us is a one stop portal links the relocation company with the customer, driver, helper, and military. MyRelo.us is a complete interface for all our product lines.</p>
                    </ul>
                        
                        <hr/>
                        <h3>General Terms </h3>
                        <h5>App and Related Terms </h5>
                        <p className="m-0 text-sm-center text-xs-center">As a user, when you download any version of the Application, these App Terms already incorporate Apple's or Google Android's terms and conditions and privacy policies ("Platform Terms"). In the event of any conflict between these service Terms and the Platform Terms then these service Terms supersede.</p><br/>
                         <p className="m-0 text-sm-center text-xs-center">It should be known that we may at any time update these terms of use. It is the users' responsibility to check these Terms of use frequently to keep-up with any modification made by us. By continuous usage of our service platforms, you are deemed to have acknowledged and accepted such modifications. If you do not agree to such modifications, you should immediately desist from the use of all our service channels.</p>
                           
                           <hr/>
                        <h3>Use of our channels (App and Website)</h3>
                           <p className="m-0 text-sm-center text-xs-center">We believe, that as a user of our website and App, you must be at least 18 years of age.it should be known that our website and App can be accessed globally by all prospective users.</p><br/>
                             <p className="m-0 text-sm-center text-xs-center">MyRelo.us hereby grants you a non-exclusive, non-transferable, revocable license to use the App for your personal, non-commercial use and only on an Apple or Android device ("Device") as permitted by the applicable Platform Terms and in accordance with these service Terms ("User Licence").  All other rights in the App and website are reserved by MyRelo.us.</p><br/>
                             <p className="m-0 text-sm-center text-xs-center">In the event when users breach any aspect of these service Terms we reserve all rights to terminate the User Licence in accordance with the EU General Data Protection Regulations (GDPR). </p><br/>
                              <p className="m-0 text-sm-center text-xs-center">You consent that your agreement with your mobile network service provider ("Mobile Provider") will apply to your use of the App. You consent that you may be charged by the Mobile network service Provider for data services while using this App or any such third party charges as may arise and you shall take responsibility for such charges. If you are not the legal bill payer for the Device used to access the App, you will be assumed to have received required expressed permission from the bill payer for using the App. </p>
                              <br/>
                              <p className="m-0 text-sm-center text-xs-center">You acknowledge that where you use services provided by Apple or Google (or any other third parties) in connection with your use of the App, you will be subject to Apple’s, Google’s (or the applicable third party’s) terms and conditions and privacy policy and you should ensure that you have read such terms. </p>
                            <hr/>
                        <h3>Intellectual Property</h3>
                        <p className="m-0 text-sm-center text-xs-center">The MyRelo.us name and logo, and other MyRelo.us trademarks, service marks, graphics and logos used in connection with the App are trademarks of MyRelo.us (collectively "MyRelo.us Trademarks").  Other trademarks, service marks, graphics and logos used in connection with the App are the trademarks of their respective owners (collectively "Third Party Trademarks").  The MyRelo.us Trademarks and Third Party Trademarks may not be copied, imitated or used, in whole or in part, without the prior written permission of MyRelo.us or the applicable trademark holder. The App and the content featured in the App are protected by copyright, trademark, patent and other intellectual property and proprietary rights which are reserved to MyRelo.us and its licensors.</p>
                          <hr/>
                        <h3>Prohibited Uses</h3>                         
                         <h5>You agree not to use the MyRelo.us App or website in any way that:</h5>
                        <p className="m-0 text-sm-center text-xs-center">Is unlawful, illegal or unauthorized;</p><br/>
                         
                         <p className="m-0 text-sm-center text-xs-center">Is defamatory of any other person;</p><br/>
                          <p className="m-0 text-sm-center text-xs-center">Is obscene or offensive;</p><br/>
                            <p className="m-0 text-sm-center text-xs-center">Promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation or age; infringes any copyright, database right or trade mark of any other person;</p><br/>
                            <p className="m-0 text-sm-center text-xs-center">Is likely to harass, upset, embarrass, alarm or annoy any other person;</p><br/>
                            <p className="m-0 text-sm-center text-xs-center">Is likely to disrupt our service in any way; or Advocates, promotes or assists any unlawful act such as (by way of example only) copyright infringement or computer misuse.</p><br/>
                             <p className="m-0 text-sm-center text-xs-center">Is likely to bring about reverse engineering, copying, or steal of our intellectual properties in any form.  </p>
                              <hr/>
                        <h3>Indemnification</h3>
                        <p className="m-0 text-sm-center text-xs-center">You agree to indemnify MyRelo.us for any breach of these website and App Terms. MyRelo.us reserves the right to control the defense and settlement of any third party claim for which you indemnify MyRelo.us under these terms and you will assist us in exercising such rights. </p>  <hr/>
                        <h3>No Promises</h3>
                        <p className="m-0 text-sm-center text-xs-center">MyRelo.us provides the website and App on an ‘as is’ and ‘as available’ basis without any promises or representations, express or implied. In particular, MyRelo.us does not warrant or make any representation regarding the validity, accuracy, reliability or availability of the website and App or their content. All users or product information provided found on our channels is based on information provided by users during registration, such data, and other content on the website or App, may be out of date and MyRelo.us makes no commitment to update it.</p><br/>
                         <p className="m-0 text-sm-center text-xs-center">To the fullest extent permitted by applicable law, MyRelo.us hereby excludes all promises, whether express or implied, including any promises that the App is fit for purpose, of satisfactory quality, non-infringing, is free of defects, is able to operate on an uninterrupted basis, that the use of the website and App by you is in compliance with laws or that any information that you transmit in connection with this App will be successfully, accurately or securely transmitted.</p><hr/>
                        <h3>Reliance on Information</h3>
                        <p className="m-0 text-sm-center text-xs-center">
                          The App is intended to provide general information only and, as such, should not be considered as a substitute for advice covering any specific situation. You should seek appropriate advice before taking or refraining from taking any action in reliance on any information contained in the App. 
                        </p>
                        <hr/>
                        <h3>Exclusion of MyRelo.us's Liability</h3>
                         <p className="m-0 text-sm-center text-xs-center">
                          To the fullest extent permitted under applicable law, in no event shall MyRelo.us be liable to you with respect to use of our website and App, or be liable to you for any direct, indirect, special or consequential damages including, without limitation, damages for loss of goodwill, lost profits, or loss, theft or corruption of your information, the inability to use the App, Device failure or malfunction.
                        </p><br/>
<p className="m-0 text-sm-center text-xs-center">
                         MyRelo.us shall not be liable even if it has been advised of the possibility of such damages, including without limitation damages caused by error, omission, interruption, defect, failure of performance, unauthorized use, delay in operation or transmission, line failure, computer virus, worm, Trojan horse or other harm. 
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
                        In the event that applicable law does not allow the exclusion of certain promises and/or the exclusion of liability for direct, indirect, consequential or other damages, in no event shall MyRelo.us’ s liability arising under or in connection with these service Terms and your use of the App exceed 50 Euro.
                        </p>
                        <hr/>
                        <h3>GDPR compliance statement</h3>
                        <p className="m-0 text-sm-center text-xs-center">
                        MyRelo.us Ltd. ("MyRelo.us") respects and complies with the EU General Data Protection Regulations (GDPR).
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
                       Some of the key ways we comply with these regulations are:
                        </p>
                        <h4>Consent</h4>
                        <p className="m-0 text-sm-center text-xs-center">
                      We explain what you’re consenting to clearly and without ‘legalese’, and ask that you explicitly consent to contact from us.
                        </p><br/>
                        <h4>Breach Notification</h4>
                        <p className="m-0 text-sm-center text-xs-center">
                     In the event of a breach we will notify affected users within 72 hours of first having become aware of the breach.
                        </p><br/>
                         <h4>Right to Access</h4>
                          <p className="m-0 text-sm-center text-xs-center">
                     Users can request confirmation as to whether or not personal data concerning them is being processed, where and for what purpose. Further, we shall provide a copy of the personal data, free of charge, in an electronic format.
                        </p><br/>
                         <h4>Right to be forgotten</h4>
                         <p className="m-0 text-sm-center text-xs-center">
                    Once we have compared your (the subjects') rights to "the public interest in the availability of the data", we may delete your personal data where you have requested this.
                        </p><br/>
                         <h4>Data Portability</h4>
                           <p className="m-0 text-sm-center text-xs-center">
                   We allow you to receive the personal data concerning you, which we will provide in a 'commonly used and machine readable format' and you have the right to transmit that data to another ‘controller’.
                        </p><br/>
                        <h4>Privacy by Design</h4>
                           <p className="m-0 text-sm-center text-xs-center">
                  We implement appropriate technical and organizational measures, in an effective way, in order to meet the requirements of this Regulation and protect the rights of data subjects'. We hold and process only the data absolutely necessary for the completion of our duties (data minimization), as well as limiting the access to personal data to those needing to act out the processing.
                        </p><br/>
                        <h4>Privacy policy</h4>
                        <p className="m-0 text-sm-center text-xs-center">
                Section 1 - What do we do with your information?
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
               If you choose to fill in a contact form on our website, we will store that information active unless you deactivate the account. However we keep your data in accordance to the provisions of the EU GDPR data protection policies. We will only use subscriber’s submitted data for the purpose of contacting you in relation to that enquiry.
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
              When you browse our site, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.
                        </p><br/>
                         <p className="m-0 text-sm-center text-xs-center">
              Email marketing: With your explicit permission, we may send you emails about our products and other updates.
                        </p><br/>
                         <p className="m-0 text-sm-center text-xs-center">
             Section 2 - Consent
                        </p><br/>
                         <p className="m-0 text-sm-center text-xs-center">
             How do you get my consent?
                        </p><br/>
                         <p className="m-0 text-sm-center text-xs-center">
             When you provide us with personal information we ask you to explicitly consent to our collecting it and using it for that specific reason only.
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
           If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
           How do I withdraw my consent?
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
          If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at any time, by contacting us at support@MyRelo.us.us or mailing us at: MyRelo.us – Division of Courageous Endeavors, Inc. PO Box 210 Sycamore, Georgia 31790-0210
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
        Section 3 - Disclosure
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
    We may disclose your personal information if we are required by law to do so.
                        </p><br/>
                         <p className="m-0 text-sm-center text-xs-center">
   Section 4 - Data Storage
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
Your data is stored through MyRelo.us’s data storage and databases. We store your data on a secure server behind a firewall.
                        </p><br/>
                         <p className="m-0 text-sm-center text-xs-center">
Section 5 - Third-party services & links
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.
                        </p><br/>
                          <p className="m-0 text-sm-center text-xs-center">
When you click on links on our App, they may direct you away from our App. We are not responsible for the privacy practices of such other sites and encourage you to read their privacy statements.
                        </p><br/>
                          <p className="m-0 text-sm-center text-xs-center">
Section 6 - Security
                        </p><br/>
                         <p className="m-0 text-sm-center text-xs-center">
To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
If you provide us with your personal information, the information is encrypted using secure socket layer technology (SSL) and stored with industry-standard encryption. Although no method of transmission over the Internet or electronic storage is 100% secure, we implement generally accepted industry standards.
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
Section 7 - Cookies
                        </p><br/>
                         <p className="m-0 text-sm-center text-xs-center">
Here is a list of cookies that we use. We’ve listed them here so you can choose if you want to opt-out of cookies or not. Opt-out by altering your web browser’s settings to reject cookies.
                        </p><br/>
                          <p className="m-0 text-sm-center text-xs-center">
Google Analytics – we use Google Analytics to measure how our App is used by visitors and to generate reports for our own use. Google Analytics does not collect any personally identifiable information about you.
                        </p><br/>
                           <p className="m-0 text-sm-center text-xs-center">
Section 8 - Changes to this privacy policy
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
General
                        </p><br/>
                         <p className="m-0 text-sm-center text-xs-center">
These App Terms shall be governed by the laws of the Republic of Ireland and the parties submit to the exclusive jurisdiction of the courts of Ireland to resolve any dispute between them arising under or in connection with these App Terms.
                        </p><br/>
                         <p className="m-0 text-sm-center text-xs-center">
If any provision (or part of a provision) of these App Terms is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
Contact Us
                        </p><br/>
                        <p className="m-0 text-sm-center text-xs-center">
If you have any questions regarding our App and other channels, you can email us at <a href="mailto:help@MyRelo.us">help@MyRelo.us</a>
                        </p>
                    </div>
            </div>
        </div>
        
        </div>
    

</section>
                <FooterMain />
            </div >
        );
    }
}

export default TermsofService;