import React, { Component } from 'react';
import HeaderMoverFor from './HeaderMoverFor';
import FooterMain from './FooterMain';

class MoverFor extends Component {
    state = {}
    render() {
        return (
            <div>
                <HeaderMoverFor />
                <div className="movers-banner">
    <div className="container">
        <div className="row">                
            <div className="col-sm-6">
                <div className="movers-banner-content">
                    <h1 className="m-0 common-banner-title wow fadeInUp" data-wow-delay="500ms">
                        Technology built for Drivers<br/>
                        revolutionizes your Moving<br/>
                        Company!
                    </h1>
                    <p className="m-0 common-banner-title-pera wow fadeInUp" data-wow-delay="500ms">
                        Save $$, Retain Drivers, Increase Customer Satisfaction,<br/>
                        Reduce Claims by 90% and so much more!
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="movers-sec1 bg-red p-t-30 p-b-30">
    <div className="container">
        <div className="row">
            <div className="col-md-4">
                <div className="movers-sec1-grid">
                    <div className="media">
                        <div className="media-left">
                            <i><img src="assets/images/icons/white/broken-link.svg" width="50"/></i>
                        </div>
                        <div className="media-body p-l-5">
                            <h5 className="m-0 p-b-5">
                                Connect with Drivers,<br/>
                                Helpers and Customers
                            </h5>
                            <p className="m-0">Our secure chat allows you to stay<br/>
                                connected with everybody involved<br/> 
                                in the move.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="movers-sec1-grid">
                    <div className="media">
                        <div className="media-left">
                            <i><img src="assets/images/icons/white/dollar-coins.svg" width="50"/></i>
                        </div>
                        <div className="media-body p-l-5">
                            <h5 className="m-0 p-b-5">
                                More Profits, Save $$
                            </h5>
                            <p className="m-0 p-b-10">Help save $$ on Hotels, Fuel,<br/>
                                Packing Material, Truck Rentals, <br/> 
                                Moving Equipment, & 1,000's of <br/> 
                                other Products and  Services.<br/>
                                GET HUGE DISCOUNT</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="movers-sec1-grid">
                    <div className="media">
                        <div className="media-left">
                            <i><img src="assets/images/icons/white/group.svg" width="50"/></i>
                        </div>
                        <div className="media-body p-l-5">
                            <h5 className="m-0 p-b-5">
                                Add efficiency and lower<br/>
                                claims
                            </h5>
                            <p className="m-0 p-b-10">Send jobs directly to ReloDrivers and
                                ReloHelpers from any device.<br/>
                                Our CYA (Cover-Your-@ss) feature saves
                                everyone $$ and ensures lower claim
                                costs

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="movers-sec2 p-t-60 p-b-40">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h2 className="m-b-30 text-bold inner-common-title">Why MyRelo?</h2>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <img src="assets/images/mobile-big.png" className="wow fadeInLeftBig" data-wow-delay="500ms"/>
                </div>
                <div className="col-md-8">
                    <div className="media p-b-30">
                        <div className="media-left">
                            <div className="icon-circle sky-light-bg">
                                <i><img src="assets/images/icons/blue/padlock.svg" width="40"/></i> 
                            </div>
                        </div>
                        <div className="media-body">
                            <h5 className="m-0 p-b-10 f-s-16 text-semi-bold text-dark-blue">Saving $$ = Financial Security</h5>
                            <p className="f-s-14">
                                Huge discounts on Hotels, Fuel, Packing Materials, Truck Rentals, Bookkeeping and tons of other products and services which lead to financial security for every one.
                            </p>
                        </div>
                    </div>
                    <div className="media p-b-30">
                        <div className="media-left">
                            <div className="icon-circle sky-light-bg">
                                <i><img src="assets/images/icons/blue/suitcase.svg" width="40"/></i> 
                            </div>
                        </div>
                        <div className="media-body">
                            <h5 className="m-0 p-b-10 f-s-16 text-semi-bold text-dark-blue">Got a Job?</h5>
                            <p className="f-s-14">
                                Our ReloDriver allows your drivers to check ReloDrivers' & ReloHelpers' availability, assign jobs, update job information, and so much more.
                            </p>
                        </div>
                    </div>
                    <div className="media p-b-30">
                        <div className="media-left">
                            <div className="icon-circle sky-light-bg">
                                <i><img src="assets/images/icons/blue/search.svg" width="40"/></i> 
                            </div>
                        </div>
                        <div className="media-body">
                            <h5 className="m-0 p-b-10 f-s-16 text-semi-bold text-dark-blue">Find Pro's who can Help</h5>
                            <p className="f-s-14">
                                Access our database of over 1,000 Professional ReloHelpers and get the job done right.
                            </p>
                        </div>
                    </div>
                    <div className="media p-b-30">
                        <div className="media-left">
                            <div className="icon-circle sky-light-bg">
                                <i><img src="assets/images/icons/blue/smile.svg" width="40"/></i> 
                            </div>
                        </div>
                        <div className="media-body">
                            <h5 className="m-0 p-b-10 f-s-16 text-semi-bold text-dark-blue">
                                Happy Driver = Satisfied Customer = More Profits
                            </h5>
                            <p className="f-s-14">
                                Our features ensure the highest level of retention of Drivers, by supplying easy-to-use tools, which <span className="text-uppercase text-sami-bold text-dark-blue">increase</span> Revenue, Lower Cost, and lead to satisfied customers.
                            </p>
                        </div>
                    </div>
                    <div className="media p-b-30">
                        <div className="media-left">
                            <div className="icon-circle sky-light-bg">
                                <i><img src="assets/images/icons/blue/settings.svg" width="40"/></i> 
                            </div>
                        </div>
                        <div className="media-body">
                            <h5 className="m-0 p-b-10 f-s-16 text-semi-bold text-dark-blue">Accountability and CYA</h5>
                            <p className="f-s-14">
                                Our revolutionary CYA (Cover-Your-@ss) feature dramatically lowers claim costs, with our easy to use ReloDriver app.
                            </p>
                        </div>
                    </div>
                    <div className="media p-b-30">
                        <div className="media-left">
                            <div className="icon-circle sky-light-bg">
                                <i><img src="assets/images/icons/blue/track.svg" width="40"/></i> 
                            </div>
                        </div>
                        <div className="media-body">
                            <h5 className="m-0 p-b-10 f-s-16 text-semi-bold text-dark-blue">Where's My Driver?</h5>
                            <p className="f-s-14">
                                
                                Real time tracking of Drivers and Helpers to ensure  <img src="assets/images/icons/smiling.svg" width="20"/>  Customers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<section className="movers-sec3 p-t-60 p-b-60">
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-center text-white">
                <h2 className="m-0 m-b-15 f-s-25 text-semi-bold">HELP YOUR TEAM BY CONNECTING TO THE LARGEST NETWORK OF<br/>
                    TOOLS BUILT BY AND FOR PROFESSIONAL DRIVERS.</h2>
                <p className="f-s-15">We GUARANTEE our app will SAVE you $$.<br/> Professionals require the right tools to get the job done.<br/> 
                    Give your drivers the best app to save $$, make $$, and INCREASE Customer Satisfaction.</p>
                <div className="number m-t-40 m-b-40">
                    4525
                </div>
                <p className="m-0 p-b-30 f-s-15">Users, Products and Services on Our Network.</p>
                <a  href="#" className="page-btn bg-red">BECOME A MEMBER NOW (It's FREE!)</a>
            </div>
        </div>
    </div>
</section>

<section className="subscription-wrap text-center">
    <div className="subscription-overlay p-t-40 p-b-60">
        <div className="container">
            <div className="row">
                <div className="col-md-12 p-b-40">
                    <h2 className="m-0 text-center common-title f-s-35 text-semi-bold">Subscription Plans</h2>
                </div>

                <div className="col-md-4 col-sm-6 col-md-offset-2">
                    <div className="pricing text-center">
                        <div className="pricing-header bg-gradient-blue">
                            <h6 className="pricing-title m-0">Free Membership</h6>
                        </div>
                        <div className="pricing-body">
                            <div className="pricing-price text-blue p-t-20">
                                FREE
                            </div>
                            <ul className="pricing-list">
                                <li>Search our database of : <br/>
                                    1,000+ Pro ReloHelpers & 
                                    <br/> 
                                    1,000+ ReloDrivers & 
                                    <br/>
                                    2,500+ Moving Companies.
                                </li>
                                <li>Save $$$ on Truck Rental, Packing
                                    Material, Hotels, Fuel, Moving Equipment, Bookkeeping,
                                    and many other products and services
                                    that you need to get the job done right!
                                </li>
                                <li>Find Truck Parking.</li>
                                <li>Manage your Schedule</li>
                                <li>Roadside Service.</li>
                                <li>Make More $$ <br/>Post your availability to do side jobs while awaiting your next shipment.</li>
                            </ul>
                        </div>
                        <div className="pricing-footer p-t-20 p-b-20">
                            <a href="#" className="pricing-footer-bg-blue">Select</a>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-6">
                    <div className="pricing text-center">
                        <div className="pricing-header bg-gradient-green">
                            <p>popular</p>
                            <h5 className="pricing-title m-0">Pro</h5>
                            <div className="offer">
                                <span>50%</span>
                                <b>OFF</b>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <div className="pricing-price text-green p-t-20">
                                <span className="pricing-currency">$</span> 15 <span>/mo. per Driver</span>
                                <p>All benefits of free membership</p>
                                <i><img src="assets/images/icons/green/add.svg" width="20"/></i>
                            </div>
                            <ul className="pricing-list">
                                <li>
                                    Reduce claims by 90% with CYA feature.<br/>
                                    (Cover-Your-@ss)
                                </li>
                                <li>Find More Loads
                                    (Help your dispatchers keep your driver  rolling, with our list of 100's of MORE shipments)</li>
                                <li>Chat with Movers, ReloHelpers,
                                    Customers, Drivers and all other Relotech
                                    members.
                                </li>
                                <li>Get Paid faster, Pay Helpers, <br/>Get Tips from Customer<br/>
                                    <span>(Coming Soon)</span>
                                </li>
                            </ul>
                        </div>
                        <div className="pricing-footer p-t-20 p-b-20">
                            <a href="#" className="pricing-footer-bg-green">Select</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
                <FooterMain />
            </div >
        );
    }
}

export default MoverFor;