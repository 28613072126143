import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class HeaderMover extends Component {
    toggleNavbar = () => {
        window.$('body').toggleClass('show-menu');
    }
    state = {}

    componentDidMount() {
        window.$('.owl-carousel').owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            dots: true,
            navText: [
                "<i class='fa fa-caret-left'></i>",
                "<i class='fa fa-caret-right'></i>"
            ],
            autoplay: true,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 4
                },
                1000: {
                    items: 4
                }
            }
        });
    }
    

    render() {

        return (
            <header className="relo-header inner-header">
                <div className="container">
                <Link to="/home" className="logo"><img src="assets/images/logo-myRelo.png" alt="Logo"/></Link>
                    <ul className="navbar">
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/cusfor">For Customer</Link></li>
                        <li><Link to="/movfor">For Mover</Link></li>
                        <li><Link to="/login" className="header-login-btn" onClick={this.toggleNavbar}>Login</Link></li>
                        <li><Link to="/registration-customer" className="header-signup-btn" onClick={this.toggleNavbar}>Sign UP</Link></li>
                    </ul>
                    <span className="navbar-toggle"></span>
                </div>
            </header>
        );
    }
}

export default HeaderMover;