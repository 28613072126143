import React, { Component } from 'react';
import { Route, Redirect, HashRouter } from 'react-router-dom';
import Storage from './externaljs/Storage';
import Urls from './externaljs/Urls';

import Login from './components/Login';
import Homepage from './components/Homepage';
import RegistrationCustomer from './components/SignUp';
import About from './components/About';
import CustomerFor from './components/CustomerFor';
import MoverFor from './components/MoverFor';
import TermsofService from './components/TermsofService';
// import RegistrationSelectProfile from './components/RegistrationSelectProfile';
// import RegistrationClient from './components/RegistrationClient';
// import RegistrationMmc from './components/SignUp';
//import Wwa from './components/Wwa';
//import GetMilApv from './components/GetMilApv';
// import RegistrationIndividual from './components/RegistrationIndividual';
 import ForgotPassword from './components/ForgotPassword';
 import OtpCheck from './components/OtpCheck';
// import RegisterStepTwo from './components/RegisterStepTwo';
 import ContactUs from './components/ContactUs';

 import PrivacyPolicy from './components/PrivacyPolicy';
// import TermsofService from './components/TermsofService';
 import SubscriptionAgreement from './components/SubscriptionAgreement';
// import MoneySavingTools from './components/MoneySavingTools';
import ResendActivation from './components/ResendActivation';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <div className="App">
          <Route path="/" exact render={
            () => { return <Redirect to="/home" /> }
          } />
          <Route path="/resend-activation" component={ResendActivation} />

          <Route path="/home" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <Homepage {...props} />
              }
            }
          } />
            <Route path="/about" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <About {...props} />
              }
            }
          } />

            <Route path="/cusfor" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <CustomerFor {...props} />
              }
            }
          } />

          <Route path="/movfor" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <MoverFor {...props} />
              }
            }
          } />
          <Route path="/terms" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <TermsofService {...props} />
              }
            }
          } />

          <Route path="/privacy-policy" render = {
            (props) => {
              if(Storage.get('customerLoginToken')!==''){
                return <Redirect to="/login"/>
              } else {
                return <PrivacyPolicy {...props}/>
              }
            }
          }/> 
           <Route path="/subscription-agreement" render = {
            (props) => {
              if(Storage.get('customerLoginToken')!==''){
                return <Redirect to="/login"/>
              } else {
                return <SubscriptionAgreement {...props}/>
              }
            }
          }/>
          <Route path="/contact-us" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <ContactUs {...props} />
              }
            }
          } />    


          {/* <Route path="/wwa" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <Wwa {...props} />
              }
            }
          } />
          <Route path="/getmilapv" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <GetMilApv {...props} />
              }
            }
          } />

          

         

          <Route path="/terms-service" render = {
            (props) => {
              if(Storage.get('customerLoginToken')!==''){
                return <Redirect to="/login"/>
              } else {
                return <TermsofService {...props}/>
              }
            }
          }/>

          <Route path="/money-saving-tools" render = {
            (props) => {
              if(Storage.get('customerLoginToken')!==''){
                return <Redirect to="/login"/>
              } else {
                return <MoneySavingTools {...props}/>
              }
            }
          }/>     

               

          <Route path="/registration-mmc" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <RegistrationMmc {...props} />
              }
            }
          } />

          <Route path="/registration-mmc-step-two" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <RegisterStepTwo {...props} />
              }
            }
          } />*/}

          <Route path="/otp-check" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <OtpCheck {...props} />
              }
            }
          } />

          <Route path="/forgot-password" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <ForgotPassword {...props} />
              }
            }
          } /> 

          <Route path="/registration-customer" render={
            (props) => {
              if (Storage.get('customerLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <RegistrationCustomer {...props} />
              }
            }
          } />      

          <Route path="/login" render={
            (props) => {
              if (Storage.get('customerLoginToken') != '') {
                if ((JSON.parse(Storage.get('customerLoginData'))).profile_id == 4) {
                  window.location.href = Urls.customerDashboard
                  return <p></p>;
                } else {
                  return <p></p>;
                }
              } else {
                return <Login {...props} />
              }
            }
          } />

        </div>
      </HashRouter>
    );
  }
}

export default App;
