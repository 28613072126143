import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';
import PostDataWithParam from '../services/PostDataWithParam'

class SignUp extends Component {
    state = {
        fields: {
            contact_no: '',
            email: '',
            organization_name: '',
            password: '',
            profile_id: 4,
            card_name: '',
            card_no: '',
            expiry_month: '',
            expiry_year: '',
            cvv_no: '',
            amount: '',
            company_contact_no: '',
            first_name: '',
            last_name: '',
            subscription_id: 1,
            website_link: '',
            position: '',
            support_email: 'help@myrelo.us',
            url: 'myrelo.us'
        },
        error: {
            confPasswordErr: '',
            dobErr: '',
            urlErr: ''
        },
        processing: false,
        regStatus: false,
        regErr: '',
    }

    componentDidMount() {
        if (this.props.location.state && typeof this.props.location.state.amount != 'undefined' && typeof this.props.location.state.subscription_id != 'undefined') {
            if (typeof this.props.location.state.email == 'undefined') {
                let fields = Object.assign({}, this.state.fields);
                fields.amount = this.props.location.state.amount;
                fields.subscription_id = this.props.location.state.subscription_id;

                this.setState({ fields: fields }, () => {

                })
            } else {
                this.setState({ fields: this.props.location.state }, () => {
                    console.log(this.state.fields)
                });
            }
        }
        this.setState({ regErr: '' });
       
    }




    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        if (e.target.name == 'company_contact_no' || e.target.name == 'contact_no' || e.target.name == 'home_contact_no') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                fields[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }

            else
            {
                fields[e.target.name]=cleaned;
            }
        }

        this.setState({ fields: fields });
    }

    initAutocomplete = (e) => {
        let fields = this.state.fields;
        fields.address = e.target.value;
        this.setState({ fields: fields });

        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: ['geocode'] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;
            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = place.formatted_address;
            this.setState({ fields: fields });

        })
    }

    handleAddMmc = (e) => {
        e.preventDefault();
        let flag = 1;

        let error = this.state.error;

        if (this.state.fields.password !== this.state.fields.confirm_password) {
            error.urlErr = '';
            error.confPasswordErr = 'Password & Confirm password does not match.';
            flag = 0;
            this.setState({ error: error });
        }
        if (flag == 1) {
            error.urlErr = '';
            error.confPasswordErr = '';
            this.setState({ error: error });
            if (this.state.fields.subscription_id == 1) {
                this.setState({ processing: true }, () => {
                    PostDataWithParam('UserRegistration', this.state.fields)
                        .then((response) => {
                            if (response.success == 1) {
                                this.setState({ regErr: response.msg, regStatus: true });
                            } else {
                                this.setState({ regErr: response.msg, processing: false, regStatus: false });
                            }
                        })
                        .catch(err => {
                            this.setState({ regErr: 'Oops! Something went wrong.', processing: false, regStatus: false });
                        })
                })
            } else {
                this.setState({ processing: true }, () => {
                    PostDataWithParam('DuplicateEmailCheck', { email_id: this.state.fields.email })
                        .then((response) => {
                            if (response.success == 1) {
                                this.setState({ processing: false }, () => {
                                    this.props.history.push({
                                        pathname: '/registration-mmc-step-two',
                                        state: this.state.fields
                                    })
                                });
                            } else {
                                this.setState({ regErr: response.msg, processing: false, regStatus: false });
                            }
                        })
                        .catch(err => {
                            this.setState({ regErr: 'Oops! Something went wrong.', processing: false, regStatus: false });
                        })
                });
            }
        }
    }

    renderSubmitBtn = () => {
        if (this.state.processing) {
            return <input type="button" className="btn btn-primary" value="Processing..." disabled={true} />
        } else {
            if (this.state.fields.subscription_id == 1)
                return <button type="submit" className="btn btn-primary">REGISTER</button>
            else
                return <button type="submit" className="btn btn-primary">CONTINUE</button>
        }
    }

    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-danger"><i className="icon-cross position-left"></i>
                    {this.state.regErr}
                </div>
            )
        }
    }

    render() {
        if (this.state.regStatus) {
            return <Redirect to={{
                pathname: '/login',
                state: { regErr: this.state.regErr }
            }} />
        }

        return (
            <section class="login-register-bg">
                <div class="login-register-box">
                    <div class="login-register-header-box text-center">
                        <div class="m-b-40">
                            <Link to="/home"><img src="assets/images/MyRelo-logo-black.png" alt="" width="235" /></Link>
                        </div>
                        <div class="btn-group">
                            <Link to="/login" className="btn border-slate text-slate-800 btn-flat text-uppercase ">Sign in</Link>
                            <Link to="/registration-customer" className="btn border-slate text-slate-800 btn-flat text-uppercase active">Create Free Account
                            <p className="m-0 f-s-13">(No Credit Card Required)</p></Link>
                        </div>
                        <br />
                        {this.showAlert(this.state.regErr)}
                    </div>
                    {/* {this.state.isMsg
                    &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-info m-b-0 text-center">
                                <p>Hey! You're signing up with MyRelo with a special add-on!</p>
                                <p>Try out <strong><i>MoverAppz.com</i></strong> for free for <strong><i>next 3 days</i></strong>, after that pay <strong><i>$99/per month</i></strong> if you wish to continue 😊</p>
                            </div>
                        </div>
                    </div>
                    } */}
                    <form action="#" method="POST" onSubmit={this.handleAddMmc} >
                        <div class="login-box-body m-t-30">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group form-group-material">
                                        <label class="control-label animate">First Name</label>
                                        <input type="text" name="first_name" value={this.state.fields.first_name} className="form-control" placeholder="First Name" onChange={this.changeValue} required={true} />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group form-group-material">
                                        <label class="control-label animate">Last Name</label>
                                        <input type="text" name="last_name" value={this.state.fields.last_name} className="form-control" placeholder="Last Name" onChange={this.changeValue} required={true} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group form-group-material">
                                        <label class="control-label animate">Email Address</label>
                                        <input type="email" name="email" value={this.state.fields.email} className="form-control" placeholder="Email Address" onChange={this.changeValue} required={true} />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group form-group-material">
                                        <label class="control-label animate">Secondary Email Address</label>
                                        <input type="email" name="alternate_email" value={this.state.fields.alternate_email} className="form-control" placeholder="Secondary Email Address" onChange={this.changeValue} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group form-group-material">
                                        <label class="control-label animate">Mobile Phone Number</label>
                                        <input type="text" name="contact_no" value={this.state.fields.contact_no} className="form-control" placeholder="Main Phone Number" onChange={this.changeValue} maxLength="10" required={true} />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group form-group-material">
                                        <label class="control-label animate">Other Contact No</label>
                                        <input type="text" name="home_contact_no" value={this.state.fields.home_contact_no} className="form-control" placeholder="Other Contact No" onChange={this.changeValue} maxLength="10" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                {/* <div class="col-md-12">
                                    <div class="form-group form-group-material">
                                        <label class="control-label animate">Current Location</label>
                                        <input type="text" name="address" id="address" value={this.state.fields.address} className="form-control" placeholder="Current Location" onChange={this.initAutocomplete} />
                                    </div>
                                </div> */}
                                <div class="col-md-6">
                                    <div class="form-group form-group-material">
                                        <label class="control-label animate">Password</label>
                                        <input type="password" name="password" value={this.state.fields.password} className="form-control" placeholder="Password" onChange={this.changeValue} required={true} />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group form-group-material">
                                        <label class="control-label animate">Password Confirmation</label>
                                        <input type="password" name="confirm_password" value={this.state.fields.confirm_password} className="form-control" placeholder="Password Confirmation" onChange={this.changeValue} required={true} />
                                        <p className="text-danger help-block">{this.state.error.confPasswordErr}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-t-20 clearfix text-center">
                                {this.renderSubmitBtn()}
                            </div>
                        </div>
                        <hr class="m-b-20" />
                        <div class="login-box-footer text-center">
                            <span class="text-grey-400"> Already have an account?</span>
                            <Link to="/login" className="link-btn">Sign In</Link>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}

export default SignUp;