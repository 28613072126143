import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import Urls from '../externaljs/Urls';

class Content extends Component {
    state = {}

    render() {
        const { navToBuild } = this.props;
        return (
            <div>
                <section className="home-banner section-gap" style={{ background: 'url(assets/images/home-banner1.jpg) no-repeat' }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 text-center text-white">
                                <div className="banner-title wow fadeInUpBig" data-wow-delay="500ms">
                                    <h1 className="m-0 p-t-20 p-b-20">Join the revolution in<br />Relocation through technology</h1>
                                    <p>
                                        MyRelo specializes in developing custom and universally easy to use technology for everybody in the relocation process to ensure the highest level of service for the customer, increased efficiency and profitability for movers, and to make -
                                        Relocation Stress Free & Easy.
                    </p>
                                </div>
                            </div>
                            <div className="banner-box-group text-center text-white">
                                <div className="banner-box-group-container">
                                    <div className="banner-box blue-bg">
                                        <i><img src="assets/images/icons/white/customerIcon.svg" width="70" /></i>
                                        <h6 className="m-0 p-t-20 p-b-20">For Customer</h6>
                                        <Link to='/cusfor'>Learn More</Link>
                                    </div>
                                    <div className="banner-box red-bg">
                                        <i><img src="assets/images/icons/white/moving-truck.svg" width="70" /></i>
                                        <h6 className="m-0 p-t-20 p-b-20">
                                            For Mover
                                        </h6>
                                        {/* <Link to='/movfor'>Learn More</Link> */}
                                        <a href="javascript:void(0)" onClick={() => navToBuild(Urls.mover)}>Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="bg-theme-grey  p-b-40 patten-bg dashboard-wall-a">
                    <div className="container">
                        <ul className="nav nav-pills">
                            <li className="active"><a data-toggle="pill" href="#CustomerFeatures">Customer Features</a></li>
                            <li className="m-l-25 m-r-25 text-muted f-s-25 m-t-5">|</li>
                            <li><a data-toggle="pill" href="#MoverFeatures">Mover Features</a></li>
                        </ul>

                        <div className="tab-content m-t-30">
                            <div id="CustomerFeatures" className="tab-pane fade in active">
                                <div className="row p-t-30 p-b-20">
                                    <div className="col-md-6">
                                        <h4 className="m-0 pull-left">My Professional Mover Tools</h4>
                                    </div>
                                    <div className="col-md-6 hidden-xs hidden-sm">
                                        <a href="Marketplace.php" className="view-text pull-right">View all</a>
                                    </div>
                                </div>

                                <div className="relo-tool-box-wrap">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-md-3 col-sm-6">
                                                    <a href="#">
                                                        <div className="relo-box">
                                                            <div className="media">
                                                                <div className="media-left">
                                                                    <figure className=""><img src="assets/images/icons/dashboard-icon/notepad.svg" width="60" alt="" /></figure>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="m-0">Get a Quote</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <a href="#">
                                                        <div className="relo-box">
                                                            <div className="media">
                                                                <div className="media-left">
                                                                    <figure className=""><img src="assets/images/icons/dashboard-icon/measuring-tape.svg" width="50" alt="" /></figure>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="m-0">Premove Survey</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <a href="#">
                                                        <div className="relo-box">
                                                            <div className="media">
                                                                <div className="media-left">
                                                                    <figure className=""><img src="assets/images/icons/dashboard-icon/updated.svg" width="60" alt="" /></figure>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="m-0">Check/Change <br />Move Date or <br />Update Info</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <a href="#">
                                                        <div className="relo-box">
                                                            <div className="media">
                                                                <div className="media-left">
                                                                    <figure className=""><img src="assets/images/icons/dashboard-icon/documents.svg" width="50" alt="" /></figure>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="m-0">My Documents</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row p-t-30 p-b-20">
                                    <div className="col-md-6">
                                        <h4 className="m-0 pull-left">My DITY Move Tools</h4>
                                    </div>
                                    <div className="col-md-6 hidden-xs hidden-sm">
                                        <a href="Marketplace.php" className="view-text pull-right">View all</a>
                                    </div>
                                </div>
                                <div className="relo-tool-box-wrap">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-md-3 col-sm-6">
                                                    <a href="#">
                                                        <div className="relo-box box-padding">
                                                            <div className="media">
                                                                <div className="media-left">
                                                                    <figure className=""><img src="assets/images/icons/dashboard-icon/packing.svg" width="60" alt="" /></figure>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="m-0">Buy Moving Supplies</h5>
                                                                    <h6 className="m-0">(Cartons, Tape, Moving Equipment)</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <a href="#">
                                                        <div className="relo-box">
                                                            <div className="media">
                                                                <div className="media-left">
                                                                    <figure className=""><img src="assets/images/icons/dashboard-icon/delivery-truck2.svg" width="50" alt="" /></figure>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="m-0">Rent a Truck</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <a href="#">
                                                        <div className="relo-box">
                                                            <div className="media">
                                                                <div className="media-left">
                                                                    <figure className=""><img src="assets/images/icons/dashboard-icon/engineer.svg" width="60" alt="" /></figure>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="m-0">Get Moving Labor</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <a href="#">
                                                        <div className="relo-box box-padding">
                                                            <div className="media">
                                                                <div className="media-left">
                                                                    <figure className=""><img src="assets/images/icons/dashboard-icon/tracking.svg" width="60" alt="" /></figure>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="m-0">Where&rsquo;s My Stuff</h5>
                                                                    <h6 className="m-0">(Real Time Shipment Tracking)</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div id="MoverFeatures" className="tab-pane fade">
                                <div className="row">
                                    <div className="col-md-3 col-sm-6">
                                        <div className="panel">
                                            <div className="panel-body text-center  p-30">
                                                <img src="assets/images/icons/checklist.svg" alt="" title="" width="80" />
                                                <h5 className="m-0 m-t-20">Get Military Approved</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="panel">
                                            <div className="panel-body text-center  p-30">
                                                <img src="assets/images/icons/box.svg" alt="" title="" width="80" />
                                                <h5 className="m-0 m-t-20">Manage ReloOrders</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="panel">
                                            <div className="panel-body text-center  p-30">
                                                <img src="assets/images/icons/id.svg" alt="" title="" width="80" />
                                                <h5 className="m-0 m-t-20">Manage Staff</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="panel">
                                            <div className="panel-body text-center  p-30">
                                                <img src="assets/images/icons/steering-wheel.svg" alt="" title="" width="80" />
                                                <h5 className="m-0 m-t-20">Manage Driver</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="panel">
                                            <div className="panel-body text-center  p-30">
                                                <img src="assets/images/icons/money-bag.svg" alt="" title="" width="80" />
                                                <h5 className="m-0 m-t-20">Manage Claims</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="panel">
                                            <div className="panel-body text-center  p-30">
                                                <img src="assets/images/icons/briefcase1.svg" alt="" title="" width="80" />
                                                <h5 className="m-0 m-t-20">Receive Offers</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="panel">
                                            <div className="panel-body text-center  p-30">
                                                <img src="assets/images/icons/delivery-truck1.svg" alt="" title="" width="80" />
                                                <h5 className="m-0 m-t-20">Find Movers</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="panel">
                                            <div className="panel-body text-center  p-30">
                                                <img src="assets/images/icons/engineer1.svg" alt="" title="" width="80" />
                                                <h5 className="m-0 m-t-20">Find ReloHelpers</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="panel">
                                            <div className="panel-body text-center  p-30">
                                                <img src="assets/images/icons/email.svg" alt="" title="" width="80" />
                                                <h5 className="m-0 m-t-20">Live Chat & Message</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="panel">
                                            <div className="panel-body text-center  p-30">
                                                <img src="assets/images/icons/happy.svg" alt="" title="" width="80" />
                                                <h5 className="m-0 m-t-20">Customer Satisfaction Survey</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                        <div className="panel">
                                            <div className="panel-body text-center  p-30">
                                                <img src="assets/images/icons/24-hours.svg" alt="" title="" width="80" />
                                                <h5 className="m-0 m-t-20">Support</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </section>
                <section className="relo-body-sec p-t-30 p-b-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="m-b-30 text-bold text-blue-deep">$$ Saving Tools</h2>
                            </div>
                            <div className="col-md-8 col-sm-8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="feature-listing m-t-20">
                                            <li>Travel Coupons & Discounts</li>
                                            <li>Sell/Rent Your House</li>
                                            <li>Fix Up My House</li>
                                            <li>Find a Home</li>
                                            <li>Find a Hotel

                            </li>
                                            <li>Book a Flight</li>

                                            <li>Book a Cruise</li>

                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="feature-listing m-t-20">
                                            <li>Vacation Packages</li>
                                            <li>Rent a Car</li>
                                            <li>Connect Utilities, Forward Mail, & More</li>
                                            <li>FAQ about the app Survey</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <figure className="feature-pic">
                                    <img src="assets/images/feature-images.png" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
                <a href="http://mypcs.us/" target="_blank">
                    <div className="relotech-military">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 text-center">
                                    <h2>Trusted by US Military</h2>

                                    <figure className="logo-mypcs">
                                        <img src="assets/images/logo-myPcs.png" alt="MyPcs Logo" />
                                    </figure>

                                </div>
                                <div className="col-md-5">
                                    <figure className="us-map">
                                        <img src="assets/images/us-map.png" alt="US Map" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
                <section className="solutions p-t-30 p-b-30">
                    <div className="container">
                        <h2 className="m-b-30 text-bold text-blue-deep">Our Solutions</h2>
                        <div className="carousel-wrap">
                            <div className="owl-carousel owl-theme">
                                <div className="item">
                                    <a href="https://getmilitaryapproved.com" target="_blank"><figure><img src="assets/images/logos/GetMilitaryApproved.png" /></figure></a>
                                </div>
                                <div className="item">
                                    <a href="https://www.relodriver.com" target="_blank"><figure><img src="assets/images/logos/ReloDriver.png" /></figure></a>
                                </div>
                                <div className="item">
                                    <a href="https://www.relohelper.com" target="_blank"><figure><img src="assets/images/logos/ReloHelper.png" /></figure></a>
                                </div>
                                <div className="item">
                                    <a href="#"><figure><img src="assets/images/logos/ReloPay.png" /></figure></a>
                                </div>
                                <div className="item">
                                    <a href="https://mypcs.us/" target="_blank"><figure><img src="assets/images/logos/MyPCS.png" /></figure></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="myrelo-app-wall">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <figure className="hidden-xs hidden-sm"><img src="assets/images/myrelo-app-mobile.png" /></figure>
                            </div>
                            <div className="col-md-8 col-sm-12">
                                <div className="myrelo-app-content">
                                    <h2 className="m-0 f-s-30 p-b-30 text-medium">
                                        Book, Track Communicate on the go.<br />
                        Take the MyRelo app with you everywhere.
                    </h2>
                                    <h5 className="m-0 p-b-25">Download the app now</h5>
                                    <ul className="appicon-list">
                                        <li><a href="#"><img src="assets/images/Apple_AppStore.png" alt="" /></a></li>
                                        <li><a href="#"><img src="assets/images/Google_PlayStore.png" alt="" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Content;