import React, { Component } from 'react';
import HeaderMain from './HeaderMoverFor';
import FooterMain from './FooterMain';

class CustomerFor extends Component {
    state = {}

    componentDidMount= ()=> {

        window.$(document).on('click mouseover', '[data-imageid]', function (e) {
            e.preventDefault();
            window.$(this).closest('.inner-body-container').find('a').removeClass('active');
            window.$(this).addClass('active');
            var imageId = window.$(this).data('imageid'),
                    imageContainer = window.$(this).data('imagecontainer');
                    window.$('#' + imageContainer + ' img.active').removeClass('active');
                    window.$('#' + imageContainer + ' img#' + imageId).addClass('active');
        });
        
    }
    render() {
        return (
            <div>
                <HeaderMain />
                <section className="inner-banner " style={{ background:'url(assets/images/member-banner.jpg) no-repeat'}}>
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-center text-white">
                <div className="banner-title wow fadeInUp" data-wow-delay="500ms">
                    <h1 className="m-0 p-t-40">Our Gift To Customers To Make <br/>Relocation Stress Free & Easy</h1>
                    <p>
                        One App with every tool imaginable to make this your Best, plus it's completely FREE!
                    </p>
                    <a href="#"><img src="assets/images/Apple_AppStore.png" width="150" className="m-r-10"/></a>
                    <a href="#"><img src="assets/images/Google_PlayStore.png" width="150"/></a>
                </div>
            </div>
        </div>
    </div> 
</section>

<section className="sky-light-bg left-bg-patten">
    <div className="container">
        <div className="row">
            <div className="col-md-3 col-sm-4 col-xs-12">
                <div className="con-icon wow fadeInLeftBig" data-wow-delay="500ms">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 512 512" style={{enableBackground:'new 0 0 512 512'}} space="preserve">
                        <g>
                            <g>
                                <path d="M476.158,231.363l-13.259-53.035c3.625-0.77,6.345-3.986,6.345-7.839v-8.551c0-18.566-15.105-33.67-33.67-33.67h-60.392
                                      V110.63c0-9.136-7.432-16.568-16.568-16.568H50.772c-9.136,0-16.568,7.432-16.568,16.568V256c0,4.427,3.589,8.017,8.017,8.017
                                      c4.427,0,8.017-3.589,8.017-8.017V110.63c0-0.295,0.239-0.534,0.534-0.534h307.841c0.295,0,0.534,0.239,0.534,0.534v145.372
                                      c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017v-9.088h94.569c0.008,0,0.014,0.002,0.021,0.002
                                      c0.008,0,0.015-0.001,0.022-0.001c11.637,0.008,21.518,7.646,24.912,18.171h-24.928c-4.427,0-8.017,3.589-8.017,8.017v17.102
                                      c0,13.851,11.268,25.119,25.119,25.119h9.086v35.273h-20.962c-6.886-19.883-25.787-34.205-47.982-34.205
                                      s-41.097,14.322-47.982,34.205h-3.86v-60.393c0-4.427-3.589-8.017-8.017-8.017c-4.427,0-8.017,3.589-8.017,8.017v60.391H192.817
                                      c-6.886-19.883-25.787-34.205-47.982-34.205s-41.097,14.322-47.982,34.205H50.772c-0.295,0-0.534-0.239-0.534-0.534v-17.637
                                      h34.739c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017H8.017c-4.427,0-8.017,3.589-8.017,8.017
                                      s3.589,8.017,8.017,8.017h26.188v17.637c0,9.136,7.432,16.568,16.568,16.568h43.304c-0.002,0.178-0.014,0.355-0.014,0.534
                                      c0,27.996,22.777,50.772,50.772,50.772s50.772-22.776,50.772-50.772c0-0.18-0.012-0.356-0.014-0.534h180.67
                                      c-0.002,0.178-0.014,0.355-0.014,0.534c0,27.996,22.777,50.772,50.772,50.772c27.995,0,50.772-22.776,50.772-50.772
                                      c0-0.18-0.012-0.356-0.014-0.534h26.203c4.427,0,8.017-3.589,8.017-8.017v-85.511C512,251.989,496.423,234.448,476.158,231.363z
                                      M375.182,144.301h60.392c9.725,0,17.637,7.912,17.637,17.637v0.534h-78.029V144.301z M375.182,230.881v-52.376h71.235
                                      l13.094,52.376H375.182z M144.835,401.904c-19.155,0-34.739-15.583-34.739-34.739s15.584-34.739,34.739-34.739
                                      c19.155,0,34.739,15.583,34.739,34.739S163.99,401.904,144.835,401.904z M427.023,401.904c-19.155,0-34.739-15.583-34.739-34.739
                                      s15.584-34.739,34.739-34.739c19.155,0,34.739,15.583,34.739,34.739S446.178,401.904,427.023,401.904z M495.967,299.29h-9.086
                                      c-5.01,0-9.086-4.076-9.086-9.086v-9.086h18.171V299.29z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M144.835,350.597c-9.136,0-16.568,7.432-16.568,16.568c0,9.136,7.432,16.568,16.568,16.568
                                      c9.136,0,16.568-7.432,16.568-16.568C161.403,358.029,153.971,350.597,144.835,350.597z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M427.023,350.597c-9.136,0-16.568,7.432-16.568,16.568c0,9.136,7.432,16.568,16.568,16.568
                                      c9.136,0,16.568-7.432,16.568-16.568C443.591,358.029,436.159,350.597,427.023,350.597z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M332.96,316.393H213.244c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017H332.96
                                      c4.427,0,8.017-3.589,8.017-8.017S337.388,316.393,332.96,316.393z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M127.733,282.188H25.119c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h102.614
                                      c4.427,0,8.017-3.589,8.017-8.017S132.16,282.188,127.733,282.188z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path d="M278.771,173.37c-3.13-3.13-8.207-3.13-11.337,0.001l-71.292,71.291l-37.087-37.087c-3.131-3.131-8.207-3.131-11.337,0
                                      c-3.131,3.131-3.131,8.206,0,11.337l42.756,42.756c1.565,1.566,3.617,2.348,5.668,2.348s4.104-0.782,5.668-2.348l76.96-76.96
                                      C281.901,181.576,281.901,176.501,278.771,173.37z"/>
                            </g>
                        </g>

                    </svg>
                </div>
            </div>
            <div className="col-md-9 col-sm-8 col-xs-12">
                <h2 className="inner-common-title m-t-30">Why Choose Us?</h2>
                <p className="f-s-16 m-b-30">
                    MyRelo was built, just for YOU, by a military veteran who knows the pain of relocation. MyRelo is the only app you will need to make your relocation STRESS FREE& EASY. Our easy to use features give you every tool necessary to accomplish your relocation mission with ease, including register for your move, rent a truck, sell/rent/buy a home, travel discounts, file a damage claim, and plus 100's of other features, discounted products and services. Plus, it's completely FREE!
                </p>
            </div>
        </div>
    </div>
</section>

<section className="p-t-60 image-section" id="section-1">
    <div className="container">

        <div className="feature-section-row">
            <div className="feature-section-col p-b-40" style={{ width: '65%'}}>
                <h2 className="m-0 inner-common-title f-s-35 text-semi-bold">Connect to Your Best Relocation Ever</h2>
                <p className="m-0 common-paragraph f-s-16 m-t-10">
                    Communication is the key to a successful relocation. Our cutting edge 
                    tools keep you connect with the Moving Company, Drivers, Helpers
                    and everyone who helps you accomplish your Relocation mission.
                </p>
                <div className="inner-body-container p-t-40 Screenshoot">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <a className="media p-b-30 show active" data-imageid="BookMove" data-imagecontainer="connecttopcs" href="">
                                <div className="media-left">
                                    <i className="circle-icon"><img src="assets/images/icons/blue/moving-truck.svg" alt="" width="30"/></i>
                                </div>
                                <div className="media-body p-l-10" >
                                    <h5 className="m-0 p-b-10 text-uppercase  text-bold">Book your Move</h5>
                                    <p className="m-0 f-s-14">Securely register for your 
                                        HHG or DITY move.</p>
                                </div>
                            </a> 
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <a className="media p-b-30 show" data-imageid="GetHelpEasily" data-imagecontainer="connecttopcs" href="">
                                <div className="media-left">
                                    <i className="circle-icon"><img src="assets/images/icons/blue/question.svg" alt="" width="30"/></i>
                                </div>
                                <div className="media-body p-l-10">
                                    <h5 className="m-0 p-b-10 text-uppercase  text-bold">Get Help Easily</h5>
                                    <p className="m-0 f-s-14">Our instant live chat with Movers, Drivers, 
                                        and Helpers via text or video.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <a className="media p-b-30 show" data-imageid="VirtualPreMove" data-imagecontainer="connecttopcs" href="">
                                <div className="media-left">
                                    <i className="circle-icon"><img src="assets/images/icons/blue/file.svg" alt="" width="30"/></i>
                                </div>
                                <div className="media-body p-l-10">
                                    <h5 className="m-0 p-b-10 text-uppercase text-bold">Virtual Pre-Move 
                                        Survey
                                    </h5>
                                    <p className="m-0 f-s-14">Our easy-to-use app allows you to
                                        complete your mandatory 
                                        pre-move survey anytime day or night 24/7.
                                    </p>
                                </div>
                            </a> 
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <a className="media p-b-30 show" data-imageid="ScheduleDelivery" data-imagecontainer="connecttopcs" href="">
                                <div className="media-left">
                                    <i className="circle-icon"><img src="assets/images/icons/blue/info-exclaimatin.svg" alt="" width="30"/></i>
                                </div>
                                <div className="media-body p-l-10">
                                    <h5 className="m-0 p-b-10 text-uppercase text-bold">Schedule Delivery/ 
                                        Update Information</h5>
                                    <p className="m-0 f-s-14">Request delivery of your shipment
                                        from storage, and keep everyone
                                        updated on your contact information.</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <a className="media p-b-30 show" data-imageid="TrackStuff" data-imagecontainer="connecttopcs" href="">
                                <div className="media-left">
                                    <i className="circle-icon"><img src="assets/images/icons/blue/box.svg" alt="" width="30"/></i>
                                </div>
                                <div className="media-body p-l-10">
                                    <h5 className="m-0 p-b-10 text-uppercase text-bold">Where&CloseCurlyQuote;s My Stuff?</h5>
                                    <p className="m-0 f-s-14">
                                        Real time shipment tracking gives you the confidence of a successful relocation.
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <a className="media p-b-30 show" data-imageid="claim-screen" data-imagecontainer="connecttopcs" href="">
                                <div className="media-left">
                                    <i className="circle-icon"><img src="assets/images/icons/blue/briefcase.svg" alt="" width="30"/></i>
                                </div>
                                <div className="media-body p-l-10">
                                    <h5 className="m-0 p-b-10 text-uppercase text-bold">File Claims & Request Reimbursements</h5>
                                    <p className="m-0 f-s-14">File a claim for your damaged product or request a reimbursement easily through MyRelo web and mobile apps.</p>
                                </div>
                            </a>
                        </div>
                    </div>


                </div>
            </div>

            <div className="feature-section-col p-b-40" style={{ width: '35%'}}>
                <div className="image-container image-container-right wow fadeInRightBig" data-wow-delay="500ms" id="connecttopcs">
                    <img src="assets/images/BookMove.png" className="active" alt="" id="BookMove" style={{boxShadow: 'none'}}/>
                    <img src="assets/images/GetHelpEasily.gif" alt="" id="GetHelpEasily"style={{boxShadow: 'none'}}/>
                    <img src="assets/images/VirtualPreMove-Mobile.jpg" alt="" id="VirtualPreMove" style={{boxShadow: 'none'}}/>
                    <img src="assets/images/ScheduleDelivery.png" alt="" id="ScheduleDelivery" style={{boxShadow: 'none'}}/>
                    <img src="assets/images/TrackStuff.png" alt="" id="TrackStuff" style={{boxShadow: 'none'}}/>
                    <img src="assets/images/claim-screen.png" alt="" id="claim-screen" style={{boxShadow: 'none'}}/>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="get-quote bg-blue text-white p-t-40 p-b-40">
    <div className="container">
        <div className="row">
            <div className="col-md-10">
                <h3 className="m-0 text-uppercase p-b-15">Paying for your own move?/ Have an Emergency move?</h3>
                <p className="f-s-16 p-b-0">Our 'Get a Quote' feature allows you to get competitive pricing from multiple moving companies instantly.</p>
            </div>
            <div className="col-md-2">
                <a href="contact-us.php" className="btn wow bounceIn m-t-15" data-wow-delay="500ms">Get a Quote</a> 
            </div>
        </div>        
    </div>
</section>

<section  className="p-t-60 image-section" id="section-1">
    <div className="container">
        <h2 className="m-0 text-center inner-common-title f-s-35 text-semi-bold">Save Money</h2>
        <p className="m-0 text-center common-paragraph p-b-20 f-s-25 text-success p-t-10">
            $ GET HUGE DISCOUNTS $
        </p>
        <div className="feature-section-row">
            <div className="feature-section-col">
                <img src="assets/images/SaveMoney.png" alt="" className="wow fadeInLeftBig" data-wow-delay="500ms" style={{boxShadow: 'none', maxWidth: '100%'}}/>
            </div>            
            <div className="feature-section-col p-b-40">
                <div className="inner-body-sec1-content p-t-30 p-l-20">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <ul className="p-l-0">
                                <li>Get a Quote</li>
                                <li>Book a Hotel</li>
                                <li>Buy Boxes & Packing Materials</li>
                                <li>Weigh My Stuff Scale Finder</li>
                                <li>Rent Self Storage Unit</li>
                                <li>Fix Up My House</li>
                                <li>Find Cheap Fuel</li>
                                <li>Book a Cruise</li>
                                <li>Rent a Car</li>
                            </ul>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <ul className="p-l-0">
                                <li>Find a Home</li>
                                <li>Connect Utilities &
                                    Forward Mail & More</li>
                                <li>Rent a Truck</li>
                                <li>Find/ Sale/ Rent a Home </li>
                                <li>Pay for My Move</li>
                                <li>Find Moving Labor</li>
                                <li>Book a Flight</li>
                                <li>Take a Vacation</li>
                            </ul>
                        </div>
                    </div>

                </div>               
            </div>            
        </div>        
    </div>
</section>
                <FooterMain />
            </div >
        );
    }
}

export default CustomerFor;