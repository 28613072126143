// export default {
//     login: 'http://localhost:3010/#/login',
//     customerDashboard: 'http://localhost:3011/#/customer/dashboard',
//     api: "https://myrelo.us/stagingRelotechApi/public/Api/",
//     mover: 'http://localhost:3001/',
//     moverLogin:'http://localhost:3001/#/login',
//     moverSignup:'http://localhost:3001/#/registration-mmc',
//     customerLogin:'http://localhost:3010/#/login',
//     customerSignup:'http://localhost:3010/#/registration-customer',
//     paypal: {
//         env: 'sandbox',  // production
//         currency: 'USD',
//         client: {
//             sandbox: 'AfimwhKCg4Gom37CBaITDbtEP3RT4wvDPo9X4mSZa7gCG8Zdlwww2Wea7EPhSEqtRCgNAQIueLc0-a7c',
//             production: 'YOUR-PRODUCTION-APP-ID',
//         }
//     }
// }

//for live server

// export default {
//     login: 'https://myrelo.us/app/',
//     customerDashboard: 'https://myrelo.us/app/customer/#/customer/dashboard',
//     api: "https://myrelo.us/relotechApi/public/Api/",
//     mover: 'https://myrelo.us/mover/',
//     moverLogin:'https://myrelo.us/mover/#/login',
//     moverSignup:'https://myrelo.us/mover/#/registration-mmc',
//     customerLogin:'https://myrelo.us/app/#/login',
//     customerSignup:'https://myrelo.us/app/#/registration-customer',
//     paypal: {
//         env: 'production',  // sandbox
//         currency: 'USD',
//         client: {
//             sandbox: 'AfimwhKCg4Gom37CBaITDbtEP3RT4wvDPo9X4mSZa7gCG8Zdlwww2Wea7EPhSEqtRCgNAQIueLc0-a7c',
//             production: 'AU6GAzqguPcD0CCcciIz2GdSWHy0GlR7P4-Z5mRkWtVE6xYq5QmdV0HlKii27IKFmhNmkHHK-b6IHRVQ',
//         }
//     }
// }

//for staging server

export default {
    login: 'https://staging.myrelo.us/',
    customerDashboard: 'https://staging.myrelo.us/customer/#/customer/dashboard',
    api: "https://myrelo.us/stagingRelotechApi/public/Api/",
    mover: 'https://staging.myrelo.us/mover/',
    moverLogin:'https://staging.myrelo.us/mover/#/login',
    moverSignup:'https://staging.myrelo.us/mover/#/registration-mmc',
    customerLogin:'https://staging.myrelo.us/#/login',
    customerSignup:'https://staging.myrelo.us/#/registration-customer',
    paypal: {
        env: 'production',  // sandbox
        currency: 'USD',
        client: {
            sandbox: 'AfimwhKCg4Gom37CBaITDbtEP3RT4wvDPo9X4mSZa7gCG8Zdlwww2Wea7EPhSEqtRCgNAQIueLc0-a7c',
            production: 'AU6GAzqguPcD0CCcciIz2GdSWHy0GlR7P4-Z5mRkWtVE6xYq5QmdV0HlKii27IKFmhNmkHHK-b6IHRVQ',
        }
    }
}