import React, { Component } from 'react';
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';

class About extends Component {
    state = {}
    render() {
        return (
            <div>
                <HeaderMain />
                <section className="inner-banner section-gap" style={{ background: 'url(assets/images/about-banner.jpg) no-repeat'}}>
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-left text-white">
                <div className="banner-title wow fadeInLeftBig" data-wow-delay="500ms">
                    <h1 className="m-0 p-t-20 text-dark-blue"><span className="text-red">Who</span> we are</h1>
                </div>
            </div>
        </div>
    </div> 
</section>

<section className="page-body-wrap p-t-40 p-b-40">
    <div className="container">
        <div className="row">
            <div className="page-section-1">
                <div className="col-md-7">
                    <div className="page-content">
                        <p className="m-0 text-sm-center text-xs-center">
                            MyRelo, a division of Courageous Endeavors, Inc, was founded by U.S.
                            Marine Corps veteran Tony Boncimino, who had seen the horrors of relocation
                            from both the military and the moving industry perspective. As a US Marine,
                            the ability and knowledge to overcome and adapt to the changing household goods
                            world has been instilled in the team at MyRelo. Our ONLY mission at MyRelo,
                            is to assist our clients in reaching new heights and increase the overall value
                            and customer service for the relocation customer. This mission will ensure our 
                            clients provide the best experience for all customers, including our military 
                            families who deserve the best relocation, we can give them.
                        </p>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="relotruck-picture">
                        <img src="assets/images/trak.jpg" alt=""/>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="page-section-2 p-t-40 p-b-20 inline-block">
                <div className="col-md-2">
                    <div className="founder-picture p-b-20 p-t-20">
                        <figure><img src="assets/images/founder.jpg" alt=""/></figure>
                        <p className="m-0 p-b-5 p-t-10">Tony Boncimino</p>
                        <span>Founder</span>
                    </div>
                </div>
                <div className="col-md-10">
                    <div className="founder-content p-t-20">
                        <p className="m-0 text-sm-center text-xs-center">
                            As the founder of Precision Movers, Inc., a military approved Storage-in-Transit 
                            and Transportation Service Provider (TSP), Tony has seen the many changes to the
                            Department of Defense/ USTRANSCOM household goods program. In early 2018, he left
                            Precision Movers, Inc. in capable hands, and has devoted himself to bring structural
                            change to the relocation industry thru technology by building the Relo Platform.
                            This one of a kind system, will revolutionize the relocation industry by connecting
                            the customer, military, mover, driver, and helper in ways never seen before. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="page-section-3 p-b-40 inline-block">
                <div className="col-md-12">
                    <h3 className="m-0 p-b-15 f-s-25 text-medium">In September 2018, Rear Admiral Clarke, who oversees USTRANSCOM, gave Relotech staff a
                        critical mission- Find radical and roundbreaking ways to do the following:</h3>
                    <ul className="listing">
                        <li>Expand Industry capacity (get more movers to participate in the program)</li>
                        <li>Create solutions to increase customer satisfaction and lower damage claims for military families</li>
                        <li>Cost Saving solutions for USTRANSCOM to add efficiency and expand mission critical </li>
                    </ul>
                    <p className="m-0 p-t-20">
                        We have designed our Relo Platform from the ground up to ensure the highest customer satisfaction and ensure productivity, efficiency and accountability. In addition by harnessing technology, we are 
                        connecting 1000&rsquo;s of movers, helpers, and military personnel in a secure,
                        custom built environment, which will allow us to expand our products in a 
                        variety of ways over the next 24 months. We look forward to your partnership
                        and hope you remain a member for many years to come. 
                    </p>
                </div>
                <div className="col-md-12 text-center p-t-30">
                    <a href="contact-us.php" className="btn btn-rounded bg-red">Become a Member Today to Change the Relocation World</a>
                </div>
            </div>
        </div>
    </div>

</section>
                <FooterMain />
            </div >
        );
    }
}

export default About;