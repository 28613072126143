import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import PostDataWithParam from '../services/PostDataWithParam';
import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';
import { FormGroupMaterial } from '../externaljs/FormGroupMaterial';

class Login extends Component {
    state = {
        email: '',
        password: '',
        profile_id: 4,
        loggedIn: false,
        emailErr: '',
        passwordErr: '',
        loginErr: '',
        regSuc: '',
        processing: false,
        urlToken:''
    }

    componentDidMount() {
        if(!!this.props.location.search)
        {
            let queryParams=new URLSearchParams(this.props.location.search);
            if(queryParams!=='')
            {
                let urlToken=queryParams.get("token");
                if(!!urlToken)
                {
                    this.setState({ urlToken: urlToken },()=>
                    {
                       this.handleActivationLogin();
                    });
                }
              
            }
        }
        if (typeof this.props.location.state !== 'undefined') {
            this.setState({ regSuc: this.props.location.state.regErr, loginErr: '' });
        }
        FormGroupMaterial();
    }

    componentWillUnmount() {
        this.setState({ regSuc: '' });
        this.setState({ loginErr: '' });
    }


    changeValue = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleActivationLogin= (e) => {
        this.setState({ processing: true }, () => {
            PostDataWithParam('AuthenticateDetails', { 'token': this.state.urlToken })
                .then((response) => {
                    let result = response;
                        if (result.success === 1) {
                            Storage.set('customerLoginToken', result.token);
                            Storage.set('customerLoginData', JSON.stringify(result.data));
                            Storage.set('customerFirstLogin', 0);
                            this.setState({ loggedIn: true });
                        } else {
                            this.setState({ loginErr: result.msg, regSuc: '', loggedIn: false, processing: false });
                        }
                    })
                    .catch(err => {
                        this.setState({ loginErr: 'Oops! Something went wrong.', regSuc: '', loggedIn: false, processing: false });
                    })
         })
    }
    handleLogin = (e) => {
        e.preventDefault();
        if (!this.state.email) {
            this.setState({ emailErr: 'Email is required.' });
        }
        else if (!this.state.password) {
            this.setState({ emailErr: '' });
            this.setState({ passwordErr: 'Password is required.' });
        } else {
            this.setState({ emailErr: '' });
            this.setState({ passwordErr: '' });

            this.setState({ processing: true }, () => {
                PostDataWithParam('Authenticate', { email: this.state.email, password: this.state.password, profile_id: 4 })
                    .then((response) => {
                        let result = response;
                        if (result.success === 1) {
                            Storage.set('customerLoginToken', result.token);
                            Storage.set('customerLoginData', JSON.stringify(result.data));
                            Storage.set('customerFirstLogin', 0);
                            this.setState({ loggedIn: true });
                        } else {
                            this.setState({ loginErr: result.msg, regSuc: '', loggedIn: false, processing: false });
                        }
                    })
                    .catch(err => {
                        this.setState({ loginErr: 'Oops! Something went wrong.', regSuc: '', loggedIn: false, processing: false });
                    })
            })
        }
    }

    renderSubmitBtn = () => {
        if (this.state.processing) {
            return <input type="button" className="btn btn-primary btn-block" value="Processing..." disabled={true} />
        } else {
            return <button type="submit" className="btn btn-primary btn-block">LOGIN</button>
        }
    }

    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-danger"><i className="icon-cross position-left"></i>
                    {this.state.loginErr}
                </div>
            )
        }
    }

    showSuccessAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-success"><i className="icon-check2 position-left"></i>
                    {this.state.regSuc}
                </div>
            )
        }
    }

    render() {

        if (this.state.loggedIn) {
            if ((JSON.parse(Storage.get('customerLoginData'))).profile_id == 4) {
                window.location.href = Urls.customerDashboard+this.props.location.search
                return <p></p>;
            }
        }

        return (
            <div>
                <section className="login-register-bg">
                    <div className="login-register-box">
                        <div className="login-register-header-box text-center">
                            <div className="m-b-40">
                                <Link to="/home">
                                    <img src="assets/images/MyRelo-logo-black.png" alt="" width="235" />
                                </Link>
                            </div>
                            <div className="btn-group">
                                <Link to="/login" className="btn border-slate text-slate-800 btn-flat text-uppercase active">Sign in</Link>
                                <Link to="/registration-customer" className="btn border-slate text-slate-800 btn-flat text-uppercase">Create Free Account
                            <p className="m-0 f-s-13">(No Credit Card Required)</p></Link>
                            </div>
                            <br />
                            {this.showAlert(this.state.loginErr)}
                            {this.showSuccessAlert(this.state.regSuc)}
                        </div>
                        <form action="#" method="POST" onSubmit={this.handleLogin}>
                        <div className="login-box-body m-t-30">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group form-group-material">
                                        <label className="control-label">Email Address</label>
                                        <input type="email" className="form-control" placeholder="Email Address" name="email" onChange={this.changeValue} required />
                                        <p className="text-danger help-block">{this.state.emailErr}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group form-group-material">
                                        <label className="control-label">Password</label>
                                        <input type="password" className="form-control" placeholder="Password" name="password" onChange={this.changeValue} required />
                                        <p className="text-danger help-block">{this.state.passwordErr}</p>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    <div className="form-group m-t-40 clearfix text-sm-center text-center">
                                        {this.renderSubmitBtn()}&nbsp;
                                        <h6 className="text-muted">OR</h6>
                                        <Link to="/forgot-password" className="btn btn-link m-t-10 p-0">Forgot Password?</Link>
                                    </div>
                                </div>
                            </div>
                            <hr className="m-t-10 m-b-10" />
                            <div className="text-center">
                            <Link to="/resend-activation" className="btn btn-link btn-block f-s-13"><u>Didn’t receive activation email?</u></Link>
                            </div>
                            <hr className="m-t-10 m-b-40" />
                            <div className="login-box-footer text-center">
                                <span className="text-grey-400"> Don't have an account?</span>
                                <Link to="/registration-customer">Sign Up Now</Link>
                            </div>
                        </div>
                        </form>
                    </div>
                </section>
            </div>
        );
    }
}

export default Login;